<template>
  <NavQuestion
      :heading="heading"
      :number="number"
      :subHeading="subHeading"
      :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionSmall
          :letter="'a'"
          :option="'Yes'"
          :image="countryImage"
          :selected="personalDetails.domiciled_in_country"
          @selected="save(true)"
      ></InputOptionSmall>
      <InputOptionSmall
          :letter="'b'"
          :option="'No/Unsure'"
          :image="'https://mylastwill.s3.amazonaws.com/static/img/button-world.png'"
          :selected="personalDetails.domiciled_in_country === false"
          @selected="save(false)"
      ></InputOptionSmall>
      <div v-if="personalDetails.domiciled_in_country === false && personalDetails.domiciled_advice_accepted === false" class="row pb30">
        <div class="col-lg-8 col-md-10 col-sm-12 col-xs-12">
          <div class="cancel">
            <div class="row">
              <div class="col-12">
                <p class="check">Creating a Will on MyLastWill is not suitable for you</p>
                <p class="check">For more information please email us at <a href="mailto:info@mylastwill.co.uk">info@mylastwill.co.uk</a> </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DomicileModal
          v-show="show.domicileModal"
          @close="show.domicileModal = false"
          @confirm="acceptDomicileAdvice"
      />
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
          v-bind:validated="isValidated"
          v-bind:forwardText="'Next'"
          v-bind:forwardLink="forwardTo"
          v-bind:backLink="backTo"
          v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionSmall from '@/common/ui/InputOptionSmall'
import DomicileModal from '@/views/pages/ProfilePage/Questions/Modals/DomicileModal'

export default {
  name: 'ProfileResidentialStatus',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionSmall,
    DomicileModal
  },
  mounted() {
    this.$emit('progress', '68%')
  },
  computed: {
    background() {
      if (this.start) return 'bg-personal-details-cookie'
      return 'bg-personal-details'
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    number() {
      if (
          this.$store.getters.checkoutBasket &&
          this.$store.getters.checkoutBasket.coupon &&
          this.$store.getters.checkoutBasket.coupon.charity
      )
        return '9'
      return '7'
    },
    start() {
      return this.$router.history.current.name === 'WriteMyWillResidentialStatus'
    },
    forwardTo() {
      if (this.start) return '/write_my_will/marital_status'
      return '/profile/marital_status'
    },
    backTo() {
      if (this.start) return '/write_my_will/address'
      return '/profile/address'
    },
    heading() {
      console.log('heading', this.personalDetails.country)
      if (this.personalDetails.country == 'England') {
        return "Are you 'domiciled' in England or Wales (see below for guidance)"
      }
      if (this.personalDetails.country == 'Wales') {
        return "Are you 'domiciled' in Wales or England? (see below for guidance)"
      }
      return `Are you 'domiciled' in ${this.personalDetails.country}? (see below for guidance)`
    },
    subHeading() {
      if (['England', 'Wales'].includes(this.personalDetails.country)) {
        return `If when you were born your parents were married AND your father was domiciled in ` +
            `England/Wales OR if they were not married and your mother was domiciled ` +
            `in England/Wales, then your domicile of origin will be England or Wales. ` +
            `If are unsure about this please answer 'No' to this question.`
      }
      return `If when you were born your parents were married AND your father was domiciled in ` +
          `${this.personalDetails.country} OR if they were not married and your mother was domiciled ` +
          `in ${this.personalDetails.country}, then your domicile of origin will be ${this.personalDetails.country}. ` +
          `If are unsure about this please answer 'No' to this question.`
    },
    isValidated() {
      if (this.personalDetails.domiciled_in_country || this.personalDetails.domiciled_advice_accepted) return true
      return false
    },
    countryImage() {
      if (this.personalDetails.country === 'England') return 'https://mylastwill.s3.amazonaws.com/static/img/button-gb.png'
      if (this.personalDetails.country === 'Wales') return 'https://mylastwill.s3.amazonaws.com/static/img/button-gb.png'
      if (this.personalDetails.country === 'Northern Ireland') return 'https://mylastwill.s3.amazonaws.com/static/img/button-world.png'
      if (this.personalDetails.country === 'Scotland') return 'https://mylastwill.s3.amazonaws.com/static/img/button-scotland.png'
      return 'https://mylastwill.s3.amazonaws.com/static/img/button-gb.png'
    }
  },
  data () {
    return {
      show: {
        domicileModal: false
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    next()
  },
  methods: {
    save(value) {
      if (this.personalDetails) {
        this.$store.commit('personalDetails', {
          domiciled_in_country: value,
        })
        if (!this.start) this.$store.dispatch('savePersonalDetails')
        if (value === false) this.show.domicileModal = true
      }
    },
    acceptDomicileAdvice (value) {
      if (this.personalDetails) {
        this.$store.commit('personalDetails', {
          domiciled_advice_accepted: value,
        })
        if (!this.start) this.$store.dispatch('savePersonalDetails')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.cancel {
  background-color: rgba(#e56781, 0.7);
  padding: 30px 30px 10px 50px;
  border-radius: 10px;
  color: white;
}
</style>
