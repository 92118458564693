<template>
  <BaseModalSmall
      @close="close"
      v-bind:isValidated="true"
      @confirm="confirm(false)"
      :noSlide="true"
  >
    <template v-slot:header>Domicile Information</template>
    <template v-slot:body>
      <div>
        <p>
          You have advised us that you may not be domiciled in {{ personalDetails.country }}. This could affect the
          laws governing your will and its distributions. If you do not have any assets outside of
          {{ personalDetails.country }} and you intend to remain in {{ personalDetails.country }} for the
          remainder of your life it may be possible that you have adopted a 'domicile of choice' and can be
          treated as domiciled in {{ personalDetails.country }}, although this can be complicated
          and specialist advice should be obtained. If you wish to continue to complete your will you do so
          with the understanding it may not completely reflect your circumstances if you are not domiciled
          in {{ personalDetails.country }} when you pass away.
        </p>
        <a @click="confirm(true)" href="#" class="link">Yes I wish to continue and understand the above.
        </a>
      </div>
    </template>
    <template v-slot:button>Cancel application</template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'

export default {
  name: 'DomicileModal',
  components: {
    BaseModalSmall
  },
  computed: {
    personalDetails() {
      return this.$store.getters.personalDetails
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    confirm(value) {
      this.$emit('confirm', value)
      this.close()
    }
  }
}
</script>
